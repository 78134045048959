<template>
  <router-link
    :to="{ name: 'my-event', params: { uuid: event.uuid } }"
    class="focus:outline-none focus:shadow-outline-gray flex w-full flex-row items-center justify-between rounded-md bg-white px-2 py-3 text-brand-dark shadow-md hover:shadow"
  >
    <div class="flex w-12 flex-shrink-0 items-center justify-center">
      <svg
        viewBox="0 0 19 24"
        class="h-8 w-8 text-center font-black"
        :class="{ 'text-brand-lightblue': !past, 'text-brand-lightgray': past }"
      >
        <g fill="currentColor" fill-rule="evenodd" transform="translate(-3)">
          <path
            d="M12.5 2.658c-3.76 0-6.82 3.048-6.82 6.795s3.06 6.795 6.82 6.795c3.762 0 6.822-3.048 6.822-6.795s-3.06-6.795-6.821-6.795zm0 12.663c-3.25 0-5.893-2.632-5.893-5.868S9.25 3.585 12.5 3.585s5.894 2.632 5.894 5.868-2.644 5.868-5.894 5.868z"
          />
          <path
            d="M12.5 0C7.269 0 3.01 4.24 3.01 9.453c0 1.902.492 3.837 1.461 5.75.768 1.516 1.836 3.022 3.175 4.476 2.271 2.467 4.51 3.916 4.604 3.976a.463.463 0 00.501 0c.094-.06 2.333-1.51 4.604-3.976 1.34-1.454 2.407-2.96 3.175-4.476.97-1.913 1.46-3.848 1.46-5.75C21.99 4.241 17.735 0 12.5 0zm4.188 19.035a26.478 26.478 0 01-4.187 3.67 26.474 26.474 0 01-4.188-3.67c-1.996-2.172-4.376-5.598-4.376-9.582 0-4.701 3.842-8.526 8.564-8.526 4.721 0 8.563 3.825 8.563 8.526 0 3.984-2.38 7.41-4.376 9.582z"
          />
          <path
            d="M15.696 8.154l-1.77-.522-1.043-1.522a.463.463 0 00-.765 0l-1.043 1.522-1.77.522a.464.464 0 00-.236.727l1.125 1.462-.05 1.844a.463.463 0 00.618.45l1.739-.618 1.738.618a.463.463 0 00.619-.45l-.051-1.844 1.125-1.462a.464.464 0 00-.236-.727zm-1.724 1.754a.463.463 0 00-.096.296l.036 1.333-1.256-.447a.464.464 0 00-.31 0l-1.257.447.037-1.333a.463.463 0 00-.096-.296l-.814-1.056 1.28-.378a.463.463 0 00.25-.182l.755-1.1.754 1.1c.06.088.149.152.25.182l1.28.378-.813 1.056z"
          />
        </g>
      </svg>
    </div>

    <div class="min-w-0 flex-1">
      <div>{{ $d(new Date(event.date_from), "short") }}</div>
      <div
        class="truncate text-base font-semibold"
        :class="{ 'text-brand-lightgray': past }"
      >
        {{ event.name }}
      </div>
    </div>

    <div
      class="flex flex-shrink-0 items-end justify-center self-center justify-self-end"
    >
      <template>
        <div v-if="!!showToggle">
          <router-link
            class="mr-2 flex w-16 items-center justify-start overflow-hidden rounded-full border border-brand-darkblue bg-gray-100 p-px transition duration-300"
            :to="{
              name: 'my-event',
              params: { uuid: event.uuid, autoCheckin: true },
            }"
          >
            <div
              class="focus:outline-none flex h-8 w-8 items-center justify-center rounded-full bg-brand-navblue shadow-circle-inner transition duration-300 focus:ring"
            >
              <svg
                viewBox="0 0 16 16"
                class="h-4 w-4 text-center text-white transition-colors duration-300"
              >
                <path
                  d="M3.994,12.5a1.988,1.988,0,0,1-1.553-.781L.248,9.226a1,1,0,0,1,1.5-1.32l2.055,2.339a.25.25,0,0,0,.368.008l8.1-8.442a1,1,0,1,1,1.45,1.378L5.519,11.822A1.941,1.941,0,0,1,3.994,12.5Z"
                  fill="currentColor"
                  stroke="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="0"
                ></path>
              </svg>
              <span class="sr-only text-xs font-medium text-brand-dark">{{
                $t("event.btn--checkin")
              }}</span>
            </div>
          </router-link>
        </div>
        <svg
          viewBox="0 0 12 22"
          class="mx-at h-6 w-6 text-7xl font-black"
          :class="{ 'text-brand-lightgray': past, 'text-brand-dark': !past }"
          v-else
        >
          <g fill="none" fill-rule="evenodd" transform="translate(-8 -1)">
            <path
              fill="currentColor"
              d="M8.412 22.089c.547.548 1.43.548 1.977 0l9.284-9.3a1.116 1.116 0 000-1.578l-9.284-9.3a1.4 1.4 0 00-1.977 1.98l8.088 8.115-8.1 8.113a1.397 1.397 0 00.012 1.97z"
            />
          </g>
        </svg>
      </template>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "EventItem",
  props: {
    event: {
      type: Object,
    },
    showToggle: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    past() {
      if (!this.event) return true;
      if (this.event.date_until) {
        return (
          new Date() >=
          new Date(new Date(this.event.date_until).setHours(23, 23, 59, 99))
        );
      }
      return (
        new Date() >=
        new Date(new Date(this.event.date_from).setHours(23, 23, 59, 99))
      );
    },
  },
};
</script>
