<template>
  <div class="relative tips-index">
    <div
      class="flex flex-col self-center justify-center w-full max-w-screen-sm px-4 pb-8 mx-auto space-y-6"
    >
      <div class="flex flex-row justify-between w-full mx-auto">
        <div class="flex flex-col items-start flex-1 space-y-1">
          <h2 class="text-3xl font-bold">{{ $t("pages.tips") }}</h2>
        </div>
        <div class="flex items-start justify-end flex-1">
          <div
            class="flex flex-row items-center justify-end flex-grow-0 flex-shrink-0 space-x-2"
          >
            <t-button variant="small" @click="getTips" :disabled="loading">
              <icon-refresh
                class="w-3 h-3"
                :class="{ 'animate-spin duration-200': loading }"
              />
              <span class="text-xs sr-only">{{ $t("action.refresh") }}</span>
            </t-button>

            <!-- <t-button variant="small" class="space-x-1" @click="filter.favorites = !filter.favorites" :disabled="true">
            <icon-bookmark class="w-3 h-3" v-if="filter.favorites" />
            <icon-bookmark-outline class="w-3 h-3" v-else />
            <span class="text-xs leading-3">{{ $t('tips.btn--filter-favorites-amount', { amount: 0 }) }}</span>
          </t-button> -->
          </div>
        </div>
      </div>

      <p class="font-medium">{{ $t("tips.intro") }}</p>

      <div class="flex flex-row items-center justify-between w-full mx-auto">
        <div class="flex flex-col items-start flex-1 space-y-1">
          <h2 class="text-xl font-semibold">
            {{ $t("tips.title--all-tips") }}
          </h2>
        </div>

        <div
          class="flex flex-row items-center justify-end flex-grow-0 flex-shrink-0 space-x-2"
        >
          <small v-if="meta">{{ meta.from }} - {{ meta.to }}</small>

          <t-button
            variant="small"
            type="button"
            v-on:click="prevPage"
            :disabled="isFirstPage"
            v-show="!isFirstPage"
            >Prev</t-button
          >
          <t-button
            variant="small"
            type="button"
            v-on:click="nextPage"
            :disabled="isLastPage"
            v-show="!isLastPage"
            >Next</t-button
          >
        </div>
      </div>

      <template v-if="tips && tips.length">
        <div class="space-y-2">
          <tip-block :tip="tip" v-for="tip in tips" :key="tip.uuid" />
          <!-- <button type="button" class="block w-full" @click="selectTip(tip)" v-for="tip in tips" :key="tip.uuid">
        </button> -->
        </div>
      </template>
      <template v-else>
        <div
          v-if="loading"
          class="flex flex-col items-center space-y-4 text-center"
        >
          <icon-loading class="w-10 h-10 text-brand-lightblue animate-spin" />
          <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
        </div>
        <div v-else>
          <p>{{ $t("tip.not-found") }}</p>
        </div>
      </template>
    </div>

    <modal :show="!!selected" @close="removeSelection">
      <div class="p-6 space-y-2" v-if="selected">
        <h5 class="font-semibold">{{ selected.title }}</h5>
        <div>{{ selected.description || "No description" }}</div>
      </div>
    </modal>
  </div>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
const TipRepository = Repository.get("tips");

import TipBlock from "components/TipBlock";
import Modal from "components/Modal";
import IconRefresh from "assets/icons/refresh.svg";
import IconLoading from "assets/icons/loading.svg";

export default {
  name: "tips",
  components: {
    TipBlock,
    IconLoading,
    IconRefresh,
    Modal
  },
  data: () => ({
    loading: false,
    tips: undefined,
    selected: undefined,
    filter: {
      used: false,
      useful: false
    },
    meta: null,
    page: 1,
    limit: 10
  }),
  methods: {
    getTips: async function() {
      this.loading = true;
      const { data } = await TipRepository.all({
        page: this.page,
        limit: this.limit
      });
      this.tips = data.tips;
      this.meta = data.meta;
      this.loading = false;
    },
    selectTip(tip) {
      this.selected = tip;
    },
    removeSelection() {
      this.selected = null;
    },
    nextPage() {
      this.page++;
      this.getTips();
    },
    prevPage() {
      this.page--;
      this.getTips();
    }
  },
  watch: {
    meta: {
      deep: true,
      handler(value) {
        if (!value) {
          return;
        }
        this.limit = value.per_page;
        this.page = value.current_page;
      }
    },
    page() {
      this.getTips();
    },
    limit() {
      this.getTips();
    }
  },
  computed: {
    isLastPage() {
      if (!this.meta || !this.page) return false;
      return this.page >= this.meta.last_page;
    },
    isFirstPage() {
      if (!this.meta || !this.page) return false;
      return this.page <= 1;
    }
  },
  created() {
    this.getTips();
  }
};
</script>
