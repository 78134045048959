<template>
  <div class="flex flex-col w-full max-w-screen-sm px-6 pt-10 mx-auto space-y-6">
    <section class="flex flex-col w-full space-y-3">

      <!-- <pre>{{ JSON.stringify(upcoming_events, null, 2)}}</pre> -->
      <!--       <pre>{{ JSON.stringify(past_events, null, 2)}}</pre> -->

      <div class="flex flex-row justify-between">
        <h2 class="text-3xl font-bold">{{ $t("events.events") }}</h2>
        <div class="flex flex-row items-center justify-end flex-grow-0 flex-shrink-0 space-x-2">
          <div
            class="relative inline-flex border border-black rounded-full shadow-sm h-7 justify-items-stretch bg-brand-dark">
            <button @click="toggleEventsView"
              class="inline-flex items-center justify-center px-2 transition duration-300 rounded-full focus:outline-none focus:ring-1 focus:ring-brand-primary"
              :class="{
                'text-white': eventsView !== 'calendar',
                'bg-white text-brand-dark': eventsView === 'calendar',
              }">
              <span class="sr-only">{{ $t("events.view-calendar") }}</span>
              <icon-calendar class="w-4 h-4" />
            </button>
            <button @click="toggleEventsView"
              class="inline-flex items-center justify-center px-2 transition duration-300 rounded-full focus:outline-none focus:ring-1 focus:ring-brand-primary"
              :class="{
                'text-white': eventsView !== 'list',
                'bg-white text-brand-dark': eventsView === 'list',
              }">
              <span class="sr-only">{{ $t("events.view-list") }}</span>
              <icon-calendar-list class="w-4 h-4" />
            </button>
          </div>

          <t-button variant="small" @click="formOpen = true">
            <icon-plus class="w-3 h-3" />
            <span class="ml-2 text-xs truncate">{{
              $t("events.btn--add-event")
              }}</span>
          </t-button>
        </div>
      </div>
      <template>
        <h3 class="text-2xl font-bold" v-if="eventsView === 'list'">
          {{ $t("events.title--upcoming-events") }}
        </h3>
        <h3 class="text-2xl font-bold" v-else-if="eventsView === 'calendar'">
          {{ $t("events.title--event-calendar") }}
        </h3>
      </template>
      <template v-if="eventsView === 'list'">
        <div class="space-y-3" v-if="upcoming_events_sorted && upcoming_events_sorted.length">
          <div :key="event.uuid" v-for="event in upcoming_events_sorted" class="relative flex items-center space-y-2">
            <div class="absolute flex items-center space-x-1 left-1">
              <div class="flex items-center justify-center w-16 h-16 mt-2 bg-red-200 rounded-lg">
                <icon-loading v-if="isDeleting" class="w-4 h-4 text-red-400 fill-current animate-spin" />
                <icon-trash v-else @click="deleteEvent(event)" class="w-6 h-6 text-red-600 fill-current" />
              </div>
            </div>
            <event-item :event="event" data-draggable="true" class="relative transition-all checklist__item"
              @touchmove.native="handleSwipe" @touchstart.native="handleSwipeStart"
              @touchend.native="handleSwipeEnd($event, 75)" />
          </div>
        </div>
        <div v-else-if="calendar_loading || loading" class="flex flex-row items-start space-x-2">
          <icon-loading class="w-4 h-4 animate-spin text-brand-dark" />
          <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
        </div>
        <p class="italic text-gray-800" v-else-if="getEventCount === 0">
          {{ $t("events.empty--upcoming-events--first") }}
        </p>
        <p class="italic text-gray-800" v-else>
          {{ $t("events.empty--upcoming-events") }}
        </p>
      </template>
    </section>

    <section class="flex flex-col space-y-6" v-if="eventsView === 'calendar'">
      <date-pick v-model="dateFilter" :hasInputElement="false" :inputAttributes="{ readonly: true }"
        :displayFormat="'DD/MM/YYYY'" :selectableYearRange="datepicker_year_range" @periodChange="updatePeriod"
        :events="calendar_events" :holidays="holidays"></date-pick>

      <div class="flex flex-row justify-between">
        <div class="flex flex-col items-start flex-1 space-y-1">
          <h3 class="flex-1 text-2xl font-bold">
            {{ $d(date_filter_object, "short") }}
          </h3>
        </div>
        <div v-if="
          date_filter_object instanceof Date &&
          date_filter_object.getDate() >= new Date(now).getDate()
        " class="flex items-start justify-end flex-1">
          <div class="flex flex-row items-center justify-end flex-grow-0 flex-shrink-0 space-x-2">
            <t-button variant="small" @click="formOpen = true">
              <icon-plus class="w-3 h-3" />
              <span class="sr-only">{{ $t("events.btn--add-event") }}</span>
            </t-button>
          </div>
        </div>
      </div>

      <div class="space-y-3" v-if="date_events && date_events.length">
        <event-item :event="event" v-for="event in date_events" :key="event.uuid" />
      </div>
      <div v-else-if="calendar_loading || loading" class="flex flex-row items-start space-x-2">
        <icon-loading class="w-4 h-4 animate-spin text-brand-dark" />
        <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
      </div>
      <p class="italic text-gray-800" v-else>
        {{ $t("events.empty--calendar-day") }}
      </p>
    </section>

    <section class="flex flex-col space-y-3" v-if="eventsView === 'list'">
      <div class="flex flex-row justify-between">
        <div class="flex flex-col items-start flex-1 space-y-1">
          <h3 class="text-2xl font-bold">
            {{ $t("events.title--past-events") }}
          </h3>
        </div>
      </div>

      <div class="space-y-3" v-if="past_events_sorted && past_events.length">
        <div :key="event.uuid" v-for="event in past_events_sorted" class="relative flex items-center space-y-2">
          <div class="absolute flex items-center space-x-1 left-1">
            <div class="flex items-center justify-center w-16 h-16 mt-2 bg-red-200 rounded-lg">
              <icon-loading v-if="isDeleting" class="w-4 h-4 text-red-400 fill-current animate-spin" />
              <icon-trash v-else @click="deleteEvent(event)" class="w-6 h-6 text-red-600 fill-current" />
            </div>
          </div>
          <event-item :event="event" data-draggable="true" class="relative transition-all checklist__item"
            @touchmove.native="handleSwipe" @touchstart.native="handleSwipeStart"
            @touchend.native="handleSwipeEnd($event, 75)" />
        </div>
      </div>
      <div v-else-if="calendar_loading || loading" class="flex flex-row items-start space-x-2">
        <icon-loading class="w-4 h-4 animate-spin text-brand-dark" />
        <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
      </div>
      <p class="italic text-gray-800" v-else>
        {{ $t("events.empty--past-events") }}
      </p>
    </section>

    <popup-pane v-if="formOpen" :title="$t('event.title--add-event')" @close="formOpen = false">
      <event-form />
    </popup-pane>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Repository from "repositories/RepositoryFactory";
import PopupPane from "components/PopupPane";
import EventItem from "components/Event/Item";
import EventForm from "components/Event/Form";
import IconCalendar from "assets/icons/calendar.svg";
import IconCalendarList from "assets/icons/calendar-list.svg";
import IconPlus from "assets/icons/plus.svg";
import IconTrash from "assets/icons/trash-bin.svg";
import IconLoading from "assets/icons/loading.svg";
import { sortBy as _sortBy, reverse as _reverse } from "lodash";
import DatePick from "components/DatePick/DatePick";
import Swipe from "../../mixins/Swipe";

const EventRepository = Repository.get("my-events");

export default {
  mixins: [Swipe],
  components: {
    DatePick,
    EventItem,
    PopupPane,
    EventForm,
    IconCalendar,
    IconCalendarList,
    IconPlus,
    IconTrash,
    IconLoading,
  },
  data: () => ({
    dateFilter: undefined,

    formOpen: false,
    events: [],
    upcoming_events: [],
    past_events: [],
    
    loading: true,
    isDeleting: false,

    calendar_events: [],
    holidays: [],
    date_events: [],
    calendar_meta: null,
    calendar_loading: true,

    period: { month: new Date().getMonth(), year: new Date().getFullYear() },
  }),
  props: {
    formOpenAtLoad: Boolean,
  },
  methods: {
    openEventFromEventCatch(/* event */) {
      this.formOpen = true;
    },
    getEvents: async function () {
      this.loading = true;

      // const all = await EventRepository.all();
      // console.log('all', all.data);
      // this.events = all.data.events;

      const past = await EventRepository.past();
      // console.log('past', past.data);
      this.past_events = past.data.events;

      // const future = await EventRepository.future(); //!
      // console.log('future', future.data);
      // this.upcoming_events = future.data.data;

      const { data } = await EventRepository.today();
      const {
        data: { data: futureEvents },
      } = await EventRepository.future();
      this.upcoming_events = [...data.events, ...futureEvents];
      
      // this.upcoming_events = future.data.data + today.data.events;

      this.loading = false;
    },

    updatePeriod(period) {
      this.period = period;
      this.getCalendarEvents(this.period);
    },
    async getHolidays() {
      const response = await EventRepository.getHolidays();
      this.holidays = response.data;
    },
    getCalendarEvents: async function (period) {
      const { month, year } = period;
      this.calendar_loading = true;
      this.calendar_events = [];
      const { data } = await EventRepository.filter({
        month: month + 1,
        year,
        overflow: 7,
      });
      this.calendar_events = data.events;
      this.calendar_meta = data.meta;
      this.calendar_loading = false;
    },

    getDateEvents: async function (date) {
      this.calendar_loading = true;
      this.date_events = [];
      const { data } = await EventRepository.date(date);
      this.date_events = data.events;
      this.calendar_loading = false;
    },
    getDateStringForPicker(dateObj) {
      return new Date(
        dateObj.getTime() - this.today.getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split("T")[0];
    },
    toggleEventsView() {
      if (this.eventsView === "list")
        this.$store.commit("general/SET_EVENTS_VIEW", "calendar");
      else this.$store.commit("general/SET_EVENTS_VIEW", "list");
    },

    async deleteEvent(event) {
      this.isDeleting = true;
      await EventRepository.delete(event.uuid);
      await this.getEvents();
      await this.getCalendarEvents(this.period);
      this.isDeleting = false;
    },
  },
  computed: {
    ...mapGetters({
      currentEvent: "currentEvent",
      isCheckedIn: "isCheckedIn",
      getEventCount: "getEventCount",
      eventsView: "general/eventsView",
      getMemberSince: "getMemberSince",
    }),
    datepicker_year_range_from() {
      return this.getMemberSince instanceof Date
        ? this.getMemberSince.getYear()
        : 2021;
    },
    datepicker_year_range() {
      return {
        from: this.datepicker_year_range_from,
        to: 2025,
      };
    },
    upcoming_events_sorted() {
      if (!(this.upcoming_events instanceof Array) || !this.upcoming_events.length) return;
      /* var events = this.events.filter((event) => {
        if (event.date_until) {
          return (
            new Date(new Date(event.date_until).setHours(23, 23, 59, 99)) >=
            this.now
          );
        }
        return (
          new Date(new Date(event.date_from).setHours(23, 23, 59, 99)) >=
          this.now
        );
      }); */

      return _sortBy(this.upcoming_events, ["date_from", "date_until", "name"]);
    },
    past_events_sorted() {
      if (!(this.past_events instanceof Array) || !this.past_events.length) return;

      /* var events = this.events.filter((event) => {
        if (event.date_until) {
          return (
            new Date(new Date(event.date_until).setHours(23, 23, 59, 99)) <
            this.now
          );
        }
        return (
          new Date(new Date(event.date_from).setHours(23, 23, 59, 99)) <
          this.now
        );
      }); */

      return _reverse(_sortBy(this.past_events, ["date_from", "date_until", "name"]));
    },
    date_filter_object() {
      if (!this.dateFilter)
        return new Date(new Date(this.now).setHours(0, 0, 0, 0));
      return new Date(new Date(this.dateFilter).setHours(0, 0, 0, 0));
    },
    now() {
      return Date.now();
    },
    todayStamp() {
      return this.today.toISOString().split("T")[0];
    },
  },

  async created() {
    this.today = new Date();
    this.dateFilter = this.todayStamp;

    await this.getEvents();
    await this.getCalendarEvents(this.period);
    if (!this.holidays.length) {
      await this.getHolidays();
    }
    if (this.formOpenAtLoad) {
      this.$nextTick(() => (this.formOpen = true));
    }
  },
  mounted() {
    this.today = new Date();
    this.eventformlistener = window.addEventListener("EVENT_OPEN_CREATE_FORM", this.openEventFromEventCatch);
  },
  beforeDestroy() {
    window.removeEventListener("EVENT_OPEN_CREATE_FORM", this.eventformlistener);
  },
  updated() {
    this.today = new Date();
  },
  watch: {
    dateFilter(newVal) {
      this.getDateEvents(newVal);
    },
    today(newVal, oldVal) {
      if (
        oldVal instanceof Date &&
        this.getDateStringForPicker(newVal) !==
          this.getDateStringForPicker(oldVal) &&
        this.dateFilter === this.getDateStringForPicker(oldVal)
      ) {
        this.dateFilter = this.getDateStringForPicker(newVal);
      }
    },
  },
};
</script>
